import Service from "./config/ConfigRepositoryAuth";

export default {
  /**
   * Obtiene todos los usuarios con sus roles.
   * @returns {Promise} Promesa que se resuelve con los datos de los usuarios.
   */
  async getAllUsers() {
    return await Service.get(`roles/users`);
  },

  /**
   * Actualiza el rol de un usuario específico.
   * @param {Object} roleData - Objeto con la información del rol, incluyendo el ID del usuario y el nuevo rol.
   * @returns {Promise} Promesa que se resuelve con la respuesta del servidor.
   */
  async updateRole(roleData) {
    // Se asume que el `roleData` incluye el ID del usuario y el nuevo rol.
    console.log(roleData, 'desde el repositorio');
    return await Service.put(`roles/update/${roleData._id}`, roleData);
  },

  /**
   * Obtiene el rol y username de un usuario específico basado en su username.
   * @param {String} username - El nombre de usuario a buscar.
   * @returns {Promise} Promesa que se resuelve con los datos del rol y username.
   */
  async getUserRoleByUsername(username) {
    console.log(username, 'desde el repositorio');
    return await Service.post(`roles/user-info`, { username });
  }
};
