import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import Roles from "../domain/Roles";

Vue.use(VueRouter);

const ifAuthenticated = async (to, from, next) => {
  if (localStorage.LCInternet && JSON.parse(localStorage.LCInternet).token) {
    const user = JSON.parse(localStorage.LCInternet).user;
    console.log("user", user);
    const { rol, username } = await Roles.getUserRoleByUsername(user.username); // Desestructuración sin _id

    console.log("rol", rol);
    const userResponse = { rol, username }; // Nuevo objeto sin _id

    if (userResponse.rol !== user.rol) {

			console.log("Los roles cambiaron")
			console.log("user", user);
			console.log("userResponse", userResponse);
			
      localStorage.LCInternet = JSON.stringify({
        token: JSON.parse(localStorage.LCInternet).token,
        user: userResponse,
      });
    }


    store.commit("setUser", userResponse); // Usar el store directamente con rol y username

    next();
  } else {
    next({ name: "Login" });
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/ingresar",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/clientes",
    name: "Clients",
    component: () =>
      import(/* webpackChunkName: "clients" */ "../views/Clients.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/clientes-moviles",
    name: "MobileClients",
    component: () =>
      import(/* webpackChunkName: "clients" */ "../views/MobileClients.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/planes-de-internet",
    name: "InternetPlans",
    component: () =>
      import(
        /* webpackChunkName: "internet-plans" */ "../views/InternetPlans.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/internet-movil",
    name: "InternetMovil",
    component: () =>
      import(
        /* webpackChunkName: "internet-movil" */ "../views/InternetMovil.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/antenas",
    name: "Antennas",
    component: () =>
      import(/* webpackChunkName: "antennas" */ "../views/Antennas.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/corte-de-caja",
    name: "CorteCaja",
    component: () =>
      import(
        /* webpackChunkName: "corte-caja" */ "../views/reports/CorteCaja.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/roles",
    name: "Roles",
    component: () =>
      import(/* webpackChunkName: "roles" */ "../views/Roles.vue"),
    beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
